import React from 'react';
import {StyleSheet, css} from "@/lib/aphrodite";


const About = () => (
  <div className={css(styles.container)}>
    <div className={css(styles.avatar)}>
      <img src={require('./assets/avatar.jpg').default} />
    </div>
    <div className={css(styles.info_block)}>
      <div>Артем Левенков</div>
      <div>мальчик на шаре</div>
    </div>
  </div>
);

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    gap: '20px',
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '> img': {
      borderRadius: '50%',
      height: '55px',
      width: '55px',
      objectFit: 'cover',
      border: 'solid 1px rgba(0,0,0,0.5)',
    },
  },
  info_block: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    gap: '5px',
    '> div': {
      display: 'flex',
      ':first-child': {
        fontFamily: 'Gilroy',
        fontWeight: 'bold',
      },
      ':last-child': {
        fontSize: '0.9em',
      },
    },
  },
});

export default About;
