import React from "react";
import { createRoot } from "react-dom/client";

import Root from "@/routes";


const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(<Root />);
