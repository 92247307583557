import React from 'react';

import Button from './Button';
import Input from './Input';

import { StyleSheet, css } from "@/lib/aphrodite";

const foo = (event) => {
  console.log(`>${event.target.dataset.value}<`);
};

const TestCard = () => {
  const [answer, setAnswer] = React.useState('');

  const buttonClicked = ({ target }) => {
    const buttonValue = target.dataset.value;

    setAnswer(answer + buttonValue);
  };

  return (
    <div className={css(styles.container)}>
      <h1>Всесторонний</h1>
      <p>прилагательное</p>

      <Input value={answer} />

      <div className={css(styles.buttonsContainer)} onClick={buttonClicked}>
        <Button>A</Button>
        <Button>b</Button>
        <Button>c</Button>
        <Button>d</Button>
        <Button>e</Button>
        <Button>f</Button>
        <Button>g</Button>
        <Button>h</Button>
        <Button>i</Button>
        <Button>m</Button>
        <br />
        <Button size="wide" value=" ">Space</Button>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 450px',
    padding: '25px 50px 50px 50px',
    maxWidth: '400px',
    background: '#FDFDFD',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.03)',
    boxSizing: 'border-box',

    '> h1': {
      fontFamily: 'Gilroy',
      fontSize: '22px',
      marginBlockStart: '1.5em',
    },
    '> h2': {
      fontFamily: 'Gilroy',
      fontSize: '18px',
      marginBlockStart: '2em',
    },
    '> h3': {
      fontFamily: 'Gilroy',
      fontSize: '16px',
      marginBlockStart: '2em',
    },
  },
  buttonsContainer: {
    marginLeft: '-5px',
    lineHeight: '40px',
    wordWrap: 'break-word',
  },
});

export default TestCard;
