import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Index from '@/components/Index/Index';
import Page from "@/components/Page/Page";

import './fonts.css';


const Root = () => (
  <BrowserRouter>
    <Routes>
      <Route index element={<Index />} />
      <Route path="/pages/:pageId" element={<Page />} />
    </Routes>
  </BrowserRouter>
);

export default Root;
