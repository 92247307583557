import React from 'react';

import App from "@/components/App/App";
import TestCard from "./TestCard/TestCard";


const Maxilex = () => (
  <App>
    <TestCard />
  </App>
);

export default Maxilex;
