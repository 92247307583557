import React from 'react';
import { StyleSheet, css } from '@/lib/aphrodite';
import LeftPanel from "@/components/LeftPanel/LeftPanel";
import ContentBlock from "@/components/ContentBlock/ContentBlock";

const App = ({ children, contentType }) => (
  <div className={css(styles.container)}>
    <div className={css(styles.leftPanel)}>
      <LeftPanel />
    </div>
    <div className={css(styles.contentBlock)}>
      <ContentBlock content={React.Children.only(children)} type={contentType} />
    </div>
  </div>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    background: '#F0F1F2',
  },
  leftPanel: {
    padding: '25px 25px 25px 50px',
    background: '#FAFAFA',
    marginRight: '1px',
    width: '250px',
  },
  contentBlock: {
    flex: 1,
    display: 'flex',
    padding: '25px 50px 25px 25px',
    background: '#FAFAFA',
  },
});

export default App;
