// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./fonts/GilroyBold.woff2";
import ___CSS_LOADER_URL_IMPORT_1___ from "./fonts/GilroyMedium.woff2";
import ___CSS_LOADER_URL_IMPORT_2___ from "./fonts/GilroyRegular.woff2";
import ___CSS_LOADER_URL_IMPORT_3___ from "./fonts/GilroyLight.woff2";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: 'Gilroy';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");\n    font-display: swap;\n    font-weight: bold;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Gilroy';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\");\n    font-display: swap;\n    font-weight: 200;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Gilroy';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\");\n    font-display: swap;\n    font-weight: normal;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Gilroy';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff2\");\n    font-display: swap;\n    font-weight: 100;\n    font-style: normal;\n}\n", "",{"version":3,"sources":["webpack://./src/fonts.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,4DAAoD;IACpD,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,4DAAsD;IACtD,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,4DAAuD;IACvD,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,4DAAqD;IACrD,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":["@font-face {\n    font-family: 'Gilroy';\n    src: url(\"@/fonts/GilroyBold.woff2\") format(\"woff2\");\n    font-display: swap;\n    font-weight: bold;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Gilroy';\n    src: url(\"@/fonts/GilroyMedium.woff2\") format(\"woff2\");\n    font-display: swap;\n    font-weight: 200;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Gilroy';\n    src: url(\"@/fonts/GilroyRegular.woff2\") format(\"woff2\");\n    font-display: swap;\n    font-weight: normal;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Gilroy';\n    src: url(\"@/fonts/GilroyLight.woff2\") format(\"woff2\");\n    font-display: swap;\n    font-weight: 100;\n    font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
