import React from 'react';
import {css, StyleSheet} from "@/lib/aphrodite";


const Separator = () => (
  <div className={css(styles.container)}>
  </div>
);

const styles = StyleSheet.create({
  container: {
    borderBottom: 'solid 1px #F0F1F2',
  },
});

export default Separator;
