import React from 'react';

import { StyleSheet, css } from '@/lib/aphrodite';


const ThemeList = ({ onTriggered }) => (
  <div className={css(styles.container)}>
    <div className={css(styles.item)}>
      <img src={require('./assets/theme_icon.png').default} />
      <div onClick={onTriggered && (() => onTriggered('climbing'))}>Скалолазание</div>
    </div>
    <div className={css(styles.item)}>
      <img src={require('./assets/theme_icon.png').default} />
      <div onClick={onTriggered && (() => onTriggered('politics'))}>Политика</div>
    </div>
    <div className={css(styles.item)}>
      <img src={require('./assets/theme_icon.png').default} />
      <div onClick={onTriggered && (() => onTriggered('damn_interesting'))}>Находки</div>
    </div>
    <div className={css(styles.item)}>
      <img src={require('./assets/theme_icon.png').default} />
      <div onClick={onTriggered && (() => onTriggered('trekking'))}>Треккинг</div>
    </div>
    <div className={css(styles.item)}>
      <img src={require('./assets/theme_icon.png').default} />
      <div onClick={onTriggered && (() => onTriggered('developing'))}>Программирование</div>
    </div>
  </div>
);

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',

    '> img': {
      width: '24px',
      height: '24px',
    },
    '> div': {
      width: '100%',
      fontFamily: 'Gilroy',
      fontSize: '13px',
      lineHeight: '100%',
      paddingLeft: '10px',
    },

    ':hover': {
      cursor: 'pointer',
      backgroundColor: '#FFF4F2',
    }
  }
});

export default ThemeList;
