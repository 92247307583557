import React from 'react';

import { Link, useNavigate } from 'react-router-dom';

import About from "./About";
import Separator from "./Separator";
import ThemeList from "./ThemeList/ThemeList";

import { StyleSheet, css } from '@/lib/aphrodite';


const LeftPanel = () => {
   const navigate = useNavigate();

  const onTriggered = theme => navigate(`/?theme=${theme}`);

  return (
    <div className={css(styles.container)}>
      <About />
      <div>
        Поедаю мягкие французские булки и запиваю чаем.
      </div>
      <Separator />
      <div className={css(styles.themeBlock)}>
        <h3>Темы публикаций</h3>
        <ThemeList onTriggered={onTriggered} />
      </div>
      <Separator />
      <div className={css(styles.termsBlock)}>
        <Link to="/pages/terms_and_conditions">Правила использования материалов</Link>
      </div>
    </div>
  );
}

const themeIcon = require('./ThemeList/assets/theme_icon.png');
const themeIconSize = 24;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  themeBlock: {
    '> h3': {
      fontFamily: 'Gilroy',
      fontWeight: 'bold',
      fontSize: '15px',
    },
    '> ul': {
      paddingInlineStart: `${(40-themeIconSize)*2}px`,
      marginBlockStart: 0,
      '> li': {
        listStyle: `url(${themeIcon.default})`,
        fontFamily: 'Gilroy',
        fontSize: '13px',
        lineHeight: `${themeIconSize}px`,

        '> span': {
          verticalAlign: 'text-bottom',
        },
      },
    },
  },
  termsBlock: {
    '> a': {
      fontFamily: 'Gilroy',
      fontSize: '0.8em',
    }
  },
});

export default LeftPanel;
