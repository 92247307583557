import React from 'react';

import Card from '@/components/CardsList/Card';

import { StyleSheet, css } from '@/lib/aphrodite';


const CardsList = ({ articles }) => (
  <div className={css(styles.container)}>
    {
      articles.map(
        article => (
          <Card
            key={article.name}
            id={article.name}
            date={article.date}
            theme={article.theme}
            title={article.title}
            color="red"
          />
        )
      )
    }
  </div>
);

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    gap: '20px',
  },
});


export default CardsList;
