import React from 'react';

import { StyleSheet, css } from "@/lib/aphrodite";

const Input = ({ value }) => (
  <div className={css(styles.container)}>{ value || (<>&nbsp;</>) }</div>
);

const styles = StyleSheet.create({
  container: {
    marginTop: 'auto',
    marginBottom: '20px',
    padding: '10px 5px',
    border: '1px solid #F0F1F2',
    borderRadius: '8px',
  },
});

export default Input;
