import React from 'react';
import * as R from "ramda";
import { useNavigate } from "react-router-dom";
import { StyleSheet, css } from '@/lib/aphrodite';


const Card = ({ id, date, theme, title, color }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/pages/${id}`)}
      className={css(styles.container, color_to_card_style(color))}
    >
      <div className={css(styles.header)}>
        <div className={css(styles.date)}>{date}</div>
        <div className={css(styles.theme)}>{theme}</div>
      </div>
      <div className={css(styles.content)}>
        <div className={css(styles.title) + " card_title"}>{title}</div>
      </div>
      <div className={css(styles.footer)}>
        <div className={css(styles.counter)}>
          <img height={20} width={20} src={require('./assets/like.svg').default} /><span>123</span>
        </div>
        <div className={css(styles.counter)}>
          <img height={20} width={20} src={require('./assets/message.svg').default} /><span>12</span>
        </div>
        <div className={css(styles.counter)}>
          <img height={20} width={20} src={require('./assets/forward.svg').default} /><span>78</span>
        </div>
        <div className={css(styles.counter)}>
          <img height={20} width={20} src={require('./assets/view_count.svg').default} /><span>23</span>
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 300,
    height: 360,
    display: 'flex',
    flexFlow: 'column',
    ':hover': {
      transition: 'box-shadow 170ms ease',
      boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.06)',
      cursor: 'pointer',
      '> div.card_title': {
        color: '#000008',
      }
    },
  },
  white_card: {
    background: "#FFFFFF",
    ':hover': {
      background: '#FDFDFE',
    },
  },
  red_card: {
    background: "#FFF4F2",
    ':hover': {
      background: '#FFF5F4',
    },
  },
  blue_card: {
    background: "#F5F4FF",
    ':hover': {
      background: '#F6F4FF',
    },
  },
  grey_card: {
    background: "#F0F1F2",
    ':hover': {
      background: '#F2F1F2',
    },
  },
  header: {
    flex: '0 0',

    display: 'flex',
    flexFlow: 'row',

    margin: '20px',

    '> div': {
      color: '#A9B9C1',
      fontSize: '0.8em',
      verticalAlign: 'middle',
    },
  },
  date: {
    flex: 1,
    fontFamily: 'Gilroy',
  },
  theme: {
    flex: 1,
    textAlign: 'right',
    fontFamily: 'Gilroy',
  },
  content: {
    padding: '40px 20px',
    flex: 1,
    display: 'flex',
    borderBottom: 'solid 1px #ECECEC',
  },
  title: {
    flex: 1,
    fontWeight: 'bold',
  },
  footer: {
    flex: '0 0',
    display: 'flex',
    flexFlow: 'row',
    margin: '20px 20px',
    gap: '0px 20px',
  },
  counter: {
    flex: 0,
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
    '> span': {
      color: '#A9B9C1',
      fontFamily: 'Gilroy',
      fontSize: '0.8em',
      lineHeight: '20px',
      marginTop: '4px',
    },
    '> img': {
    },
  },
});

const color_to_card_style = color => {
  return R.propOr(styles.white_card, color)({
    "red": styles.red_card,
    "blue": styles.blue_card,
    "grey": styles.grey_card,
  });
};

export default Card;
