import React from 'react';

import ContentMenu from "@/components/ContentMenu/ContentMenu";

import { StyleSheet, css } from '@/lib/aphrodite';


const ContentBlock = ({ content, type }) => (
  <div className={css(styles.container)}>
    <div className={css(styles.contentMenu)}>
      <ContentMenu />
    </div>
    <div className={css(styles.cardsList, styleByType(type))}>
      { content }
    </div>
  </div>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  contentMenu: {
    paddingBottom: '25px',
    borderBottom: 'solid 1px #F0F1F2',
  },
  cardsList: {
    paddingTop: '25px',

    flex: 1,
  },
  cardsContent: {
  },
  plainContent: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const styleByType = type => {
  if (type === "cards")
    return styles.cardsContent;

  return styles.plainContent;
};

export default ContentBlock;
