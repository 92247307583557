import React from 'react';

import { StyleSheet, css } from "@/lib/aphrodite";


const Button = ({ children, size, value }) => (
  <span data-value={value || children} className={css(styles.button, size && styles[size])}>
    {children}
  </span>
);

const styles = StyleSheet.create({
  button: {
    display: 'inline',
    padding: '5px 10px',
    margin: '0 5px',
    background: '#FDFDFD',
    border: '1px solid #F0F1F2',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'all 0.1s ease-in-out',

    ':hover': {
      background: '#F8F8F8',
    },
  },
  wide: {
    padding: '5px 50px',
  },
});

export default Button;
