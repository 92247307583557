import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from "react-router-dom";

import App from "@/components/App/App";
import { StyleSheet, css } from '@/lib/aphrodite';


const Page = () => {
  const [description, setDescription] = useState({});
  const location = useLocation();
  useEffect(() => setDescription({}), [location]);

  return (
    <App>
      <div className={css(styles.container)}>
        <div className={css(styles.footer)}>
          <div className={css(styles.counter)}>
            <span>{ description.date || '--.--.----' }</span>
          </div>
          <div className={css(styles.counter)}>
            <img height={20} width={20} src={require('./assets/forward.svg').default} /><span>78</span>
          </div>
          <div className={css(styles.counter)}>
            <img height={20} width={20} src={require('./assets/view_count.svg').default} /><span>23</span>
          </div>
        </div>

        {(() => {
          const pageId = useParams().pageId;

          try {
            const PageComponent = React.lazy(() => import(`./pages/${pageId}`));

            return (
              <React.Suspense fallback={<p>Loading...</p>}>
                <PageComponent
                  descriptionCallback={description => { setDescription(description); }}
                />
              </React.Suspense>
            );
          } catch (e) {
            if (e.code === 'MODULE_NOT_FOUND')
              return <>Page not found</>;
            else {
              console.log(e);

              return <>Unexpected error occurred</>;
            }
          }
        })()}
      </div>
    </App>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: '25px 50px 25px 50px',
    maxWidth: '800px',
    background: '#FDFDFD',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.03)',

    '> h1': {
      fontFamily: 'Gilroy',
      fontSize: '22px',
      marginBlockStart: '1.5em',
    },
    '> h2': {
      fontFamily: 'Gilroy',
      fontSize: '18px',
      marginBlockStart: '2em',
    },
    '> h3': {
      fontFamily: 'Gilroy',
      fontSize: '16px',
      marginBlockStart: '2em',
    },
  },
  footer: {
    flex: '0 0',
    display: 'flex',
    flexFlow: 'row',
    gap: '0px 20px',
  },
  counter: {
    flex: 0,
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
    '> span': {
      color: '#A9B9C1',
      fontFamily: 'Gilroy',
      fontSize: '0.8em',
      lineHeight: '20px',
      marginTop: '4px',
    },
    '> img': {
    },
  },
});

export default Page;
