import React, {useEffect, useState} from 'react';
import Axios from 'axios';

import App from "@/components/App/App";
import CardsList from "@/components/CardsList/CardsList";


const Index = () => {
  const [articles, setArticles] = useState([]);
  useEffect(
    () => {
      // This is needed, because webpack-dev-server is not rewriting url if Accept=application/json.
      delete Axios.defaults.headers.common['Accept'];

      Axios.get('/api/articles/').then(({ data }) => setArticles(data));
    },
    [location]
  );

  return (
    <App contentType={"cards"}>
      <CardsList articles={articles} />
    </App>
  );
}

export default Index;
