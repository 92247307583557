import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { StyleSheet, css } from '@/lib/aphrodite';


const ContentMenuItem = ({ children, route, disabled, outerStyles }) => {
  const location = useLocation();
  const navigate = useNavigate();

  let itemStyle = styles.item;

  if (location.pathname === route)
    itemStyle = styles.currentItem;

  if (disabled)
    itemStyle = styles.disabled;

  const className = css(
    outerStyles,
    styles.common,
    itemStyle,
  );

  const onClicked = () => navigate(route);

  return (
    <div onClick={onClicked} className={className}>
      {children}
    </div>
  );
};

const styles = StyleSheet.create({
  common: {
    cursor: 'pointer',
    fontFamily: 'Gilroy',
    fontWeight: 100,
    fontSize: '13px',
  },
  currentItem: {
    fontWeight: 'bold',
    color: '#1511DC',
  },
  item: {
    ':hover': {
      color: '#1511DC',
    },
  },
  disabled: {
    color: '#DBDBDB',
    cursor: 'default',
  },
});

export default ContentMenuItem;
