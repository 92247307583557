import React from 'react';
import {StyleSheet, css} from "@/lib/aphrodite";


const onClicked = () => alert("Ooops! This feature is still under construction, sorry!");

const LanguageSelector = ({ outerStyles }) => (
  <div onClick={onClicked} className={css(outerStyles || null, styles.container)}>
    <div className={css(styles.flagContainer)}>
      <img src={require('./assets/ru.svg').default} />
    </div>
    <div className={css(styles.langName)}>Russian</div>
  </div>
);

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    gap: '5px',
  },
  flagContainer: {
    '> img': {
      width: '24px',
      height: '16px',
      objectFit: 'cover',
    }
  },
  langName: {
    fontFamily: 'Gilroy',
    fontWeight: 100,
    fontSize: '0.8em',
    lineHeight: '18px',
  },
});

export default LanguageSelector;
