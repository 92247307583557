import React from 'react';

import LanguageSelector from "./LanguageSelector/LanguageSelector";
import ContentMenuItem from "@/components/ContentMenu/ContentMenuItem";

import { StyleSheet, css } from '@/lib/aphrodite';


const ContentMenu = () => (
  <div className={css(styles.container)}>
    <ContentMenuItem route="/" outerStyles={styles.item}>Публикации</ContentMenuItem>
    <ContentMenuItem route="/pages/about" outerStyles={styles.item}>Об этом блоге</ContentMenuItem>
    <ContentMenuItem outerStyles={styles.item} disabled={true}>Поиск...</ContentMenuItem>
    <LanguageSelector outerStyles={styles.item} />
  </div>
);

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  item: {
    ':first-child': {
      marin: 0,
      marginRight: '30px',
    },
    ':not(:first-child)': {
      marginLeft: '0px',
      marginRight: '30px',
    },
    ':last-child': {
      marginLeft: 'auto',
      marginRight: '0px',
    }
  },
});

export default ContentMenu;
